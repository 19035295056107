import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { useEffect } from 'react';
import { useIsSignedMsgUserOrdersAccountInitialized } from '../signedMsgOrders/useIsSignedMsgUserOrdersAccountInitialized';
import useLocalStorageState from '../useLocalStorageState';
import useUserAccountPubKey from '../useUserAccountPubKey';
import useCurrentWalletAdapter from '../useCurrentWalletAdapter';

export const useSwiftAnnouncementNotificationModal = () => {
	const setStore = useDriftStore((s) => s.set);
	const { connected } = useCurrentWalletAdapter();
	const userAccount = useUserAccountPubKey();
	const { data: isSignedMsgOrdersInitialized, isLoading } =
		useIsSignedMsgUserOrdersAccountInitialized();
	const [hasSeenSwiftAnnouncement] = useLocalStorageState(
		'hasSeenSwiftAnnouncement',
		false
	);

	useEffect(() => {
		if (
			isLoading === false &&
			!isSignedMsgOrdersInitialized &&
			!hasSeenSwiftAnnouncement &&
			!!userAccount &&
			connected
		) {
			setStore((s) => {
				s.modals.showSwiftAnnouncementModal = true;
			});
		}
	}, [
		isLoading,
		isSignedMsgOrdersInitialized,
		hasSeenSwiftAnnouncement,
		userAccount,
		connected,
	]);
};
