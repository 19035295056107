'use client';

import GridSectionWrapper from 'src/components/GridSections/GridSectionWrapper/GridSectionWrapper';
import MarginInfo from 'src/components/MarginInfo/MarginInfo';
import LayoutGrid from 'src/components/Grids/LayoutGrid/LayoutGrid';
import BREAKPOINTS from 'src/constants/breakpoints';
import useLayoutConfigSetting from 'src/hooks/useLayoutConfigSetting';
import dynamic from 'next/dynamic';
import React, { useCallback } from 'react';
import MarketSelector from '../MarketSelector';
import { TradeForm } from '../TradeForm/TradeForm';
import TradePageTable from '../TradePageTable/TradePageTable';
import {
	PRO_GRID_CONFIGURATIONS,
	LITE_GRID_CONFIGURATIONS,
	TABLET_CONFIG,
} from './GridConfigurations';
import MobileTradePage from './MobileTradePage';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import OrderbookAndRecentTrades from '../OrderbookAndRecentTrades';
import DraggableTableResizer from '../DraggableTableResizer';
import MarketBar from '../MarketBar/MarketBar';
import useCurrentSettings from 'src/hooks/useCurrentSettings';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import useIsLiteMode from 'src/hooks/useIsLiteMode';
import useIsTabletOrSmallerSize from 'src/hooks/useIsTabletOrSmallerSize';
import HighlightedTabs from '../TabbedNavigation/HighlightedTabs';
import RecentMarketTrades from '../RecentMarketTrades';
import Orderbook from '../Orderbook/Orderbook';
import { NetAccountValueDisplayLite } from '../NetAccountValueDisplayLite';
import { useDisplayStalePageWarning } from '../../hooks/useDisplayStalePageWarning';
import { useSwiftAnnouncementNotificationModal } from 'src/hooks/swift/useSwiftAnnouncementNotificationModal';

const TVChartContainer = dynamic(
	() =>
		import('../TradingView').then(
			(mod) =>
				mod.TVChartContainer as React.ComponentType<{ isMobile?: boolean }>
		),
	{ ssr: false }
);

const getSelectedConfig = (
	layoutSetting: string,
	isLiteMode?: boolean,
	isTabletScreenSize?: boolean
) =>
	isLiteMode
		? isTabletScreenSize
			? TABLET_CONFIG
			: LITE_GRID_CONFIGURATIONS.find((grid) => {
					return grid.value === layoutSetting;
			  }) || LITE_GRID_CONFIGURATIONS[0]
		: isTabletScreenSize
		? TABLET_CONFIG
		: PRO_GRID_CONFIGURATIONS.find((grid) => {
				return grid.value === layoutSetting;
		  }) || PRO_GRID_CONFIGURATIONS[0];

type TabletTabsOption = 'chart' | 'trades' | 'orderbook';

const tabOptions = [
	{ value: 'chart', label: 'Chart' },
	{ value: 'orderbook', label: 'Orderbook' },
	{ value: 'trades', label: 'Recent Trades' },
];

const InnerGrid = () => {
	// Tabs for tablet mode switching between tradingview/orderbook/recent trades
	// We fully render the tabs here, instead of in a separate component, in order to prevent tradingview from re-rendering/reloading
	// When switching from tablet to desktop screen size
	const [tabletSelectedTab, setTabletSelectedTab] =
		React.useState<TabletTabsOption>('chart');

	const [layoutSetting] = useLayoutConfigSetting();
	const selectedMarketType = useDriftStore(
		(s) => s.selectedMarket.current.marketType
	);
	const isLiteMode = useIsLiteMode();
	const isTabletScreenSize = useIsTabletOrSmallerSize();

	// All inner grids need to be the same for all layouts and sizes, to avoid tradingview from being reloaded
	// Just set "render condition" to false for the elemets that should not be rendered
	// Reloading tradingview causes a memory leak + dom elements + event listeners leak

	const selectedConfig = getSelectedConfig(
		layoutSetting,
		isLiteMode,
		isTabletScreenSize
	);

	const innerConfig = selectedConfig.config.inner;
	const sections = innerConfig.sections;

	const handleChangeTabletTab = useCallback(
		(selection: string) => setTabletSelectedTab(selection as TabletTabsOption),
		[]
	);

	// Pro mode inner grid
	return (
		<LayoutGrid
			className={`h-full overflow-hidden ${
				isLiteMode && !isTabletScreenSize ? 'gap-0' : ''
			}`}
			breakpointsStyleDefinitions={[
				{
					breakPoint: BREAKPOINTS.SMALL,
					columnDefs: innerConfig.columnDefs,
					rowDefs: innerConfig.rowDefs,
				},
			]}
			cells={[
				{
					// Lite mode only
					Element: <MarketSelector />,
					cellBreakpoints: [
						[
							BREAKPOINTS.SMALL,
							sections.marketSelector?.colStart,
							sections.marketSelector?.colSpan,
							sections.marketSelector?.rowStart,
							sections.marketSelector?.rowSpan,
						],
					],
					skipWrapper: true,
					key: 'marketSelectorInner',
					renderCondition: () => isLiteMode && !isTabletScreenSize,
				},
				{
					// Tablet only
					Element: (
						<GridSectionWrapper noPad>
							<TradeForm marketType={selectedMarketType} />
						</GridSectionWrapper>
					),
					cellBreakpoints: [
						[
							BREAKPOINTS.SMALL,
							sections.tradeForm?.colStart,
							sections.tradeForm?.colSpan,
							sections.tradeForm?.rowStart,
							sections.tradeForm?.rowSpan,
						],
					],
					skipWrapper: true,
					key: 'tradeFormInner',
					renderCondition: () => isTabletScreenSize,
				},
				{
					// Pro mode only
					Element: (
						<GridSectionWrapper noPad className="flex">
							<OrderbookAndRecentTrades />
						</GridSectionWrapper>
					),
					cellBreakpoints: [
						[
							BREAKPOINTS.SMALL,
							sections.recentTrades?.colStart,
							sections.recentTrades?.colSpan,
							sections.recentTrades?.rowStart,
							sections.recentTrades?.rowSpan,
						],
					],
					skipWrapper: true,
					renderCondition: () => !isLiteMode && !isTabletScreenSize,
				},
				{
					Element: (
						<GridSectionWrapper
							noPad
							className={`flex flex-col ${
								!isTabletScreenSize && isLiteMode
									? 'border-y-0 rounded-none'
									: ''
							}`}
						>
							{isTabletScreenSize && !isLiteMode && (
								<HighlightedTabs
									onChange={handleChangeTabletTab}
									currentSelection={tabletSelectedTab}
									options={tabOptions}
									opts={{
										fullWidth: true,
										growTabs: true,
									}}
								/>
							)}
							<div
								className={`h-full ${
									isTabletScreenSize &&
									tabletSelectedTab !== 'chart' &&
									!isLiteMode
										? 'hidden'
										: ''
								}`}
							>
								<TVChartContainer />
							</div>
							{isTabletScreenSize &&
								tabletSelectedTab === 'trades' &&
								!isLiteMode && <RecentMarketTrades />}
							{isTabletScreenSize &&
								tabletSelectedTab === 'orderbook' &&
								!isLiteMode && <Orderbook />}
						</GridSectionWrapper>
					),
					cellBreakpoints: [
						[
							BREAKPOINTS.SMALL,
							sections.tvChart.colStart,
							sections.tvChart.colSpan,
							sections.tvChart.rowStart,
							sections.tvChart.rowSpan,
						],
					],
					skipWrapper: true,
				},
				{
					Element: (
						<div>
							{!isTabletScreenSize && <DraggableTableResizer />}
							<GridSectionWrapper noPad className="overflow-visible">
								<TradePageTable hideMinimiser />
							</GridSectionWrapper>
						</div>
					),
					cellBreakpoints: [
						[
							BREAKPOINTS.SMALL,
							sections.userInfo.colStart,
							sections.userInfo.colSpan,
							sections.userInfo.rowStart,
							sections.userInfo.rowSpan,
						],
					],
					skipWrapper: true,
				},
			]}
		/>
	);
};

//// Refer to this mockup to understand the layout of the two grids: https://www.figma.com/file/urFQ3BJJY1g4rafubfVLhK/Drift-Finance-Designs?node-id=7219%3A64907
const OuterGrid = () => {
	const selectedMarketType = useDriftStore(
		(s) => s.selectedMarket.current.marketType
	);

	const isLiteMode = useIsLiteMode();
	const isTabletScreenSize = useIsTabletOrSmallerSize();

	const [layoutSetting] = useLayoutConfigSetting();

	// todo: override this on tablet
	const selectedConfig = getSelectedConfig(
		layoutSetting,
		isLiteMode,
		isTabletScreenSize
	);

	const sections = selectedConfig.config.outer.sections;

	return (
		<LayoutGrid
			breakpointsStyleDefinitions={[
				{
					breakPoint: BREAKPOINTS.SMALL,
					rowDefs: selectedConfig.config.outer.rowDefs,
					columnDefs: selectedConfig.config.outer.columnDefs,
				},
			]}
			// @ts-ignore
			cells={[
				{
					// Only pro mode
					Element: <MarketSelector />,
					cellBreakpoints: [
						[
							BREAKPOINTS.SMALL,
							sections.marketSelector?.colStart,
							sections.marketSelector?.colSpan,
							sections.marketSelector?.rowStart,
							sections.marketSelector?.rowSpan,
						],
					],
					skipWrapper: true,
					key: 'marketSelectorOuter',
					renderCondition: () => !isLiteMode || isTabletScreenSize,
				},
				{
					// Only pro mode
					Element: (
						<GridSectionWrapper
							noPad
							className="relative overflow-visible border-none"
						>
							<MarginInfo />
						</GridSectionWrapper>
					),
					cellBreakpoints: [
						[
							BREAKPOINTS.SMALL,
							sections.marginInfo?.colStart,
							sections.marginInfo?.colSpan,
							sections.marginInfo?.rowStart,
							sections.marginInfo?.rowSpan,
						],
					],
					skipWrapper: true,
					key: 'marginInfoOuter',
					renderCondition: () => !isLiteMode && !isTabletScreenSize,
				},
				{
					Element: (
						<div className={isLiteMode ? '' : 'h-full'}>
							<GridSectionWrapper
								noPad
								className={`${isLiteMode ? 'h-auto' : ''}`}
							>
								<TradeForm marketType={selectedMarketType} />
							</GridSectionWrapper>
							{isLiteMode && (
								<GridSectionWrapper className="p-4 pt-6 bg-transparent border-none">
									<NetAccountValueDisplayLite />
								</GridSectionWrapper>
							)}
						</div>
					),
					cellBreakpoints: [
						[
							BREAKPOINTS.SMALL,
							sections.tradeForm.colStart,
							sections.tradeForm.colSpan,
							sections.tradeForm.rowStart,
							sections.tradeForm.rowSpan,
							undefined,
							false,
						],
					],
					skipWrapper: true,
					key: 'tradeFormOuter',
					renderCondition: () => !isTabletScreenSize, // rendered in the inner grid on tablet
				},
				{
					Element: <InnerGrid />,
					cellBreakpoints: [
						[
							BREAKPOINTS.SMALL,
							sections.innerGrid.colStart,
							sections.innerGrid.colSpan,
							sections.innerGrid.rowStart,
							sections.innerGrid.rowSpan,
						],
					],
					skipWrapper: true,
					key: 'innerGridOuter',
				},
			]}
			className={isLiteMode && !isTabletScreenSize ? 'gap-4 h-full' : 'h-full'}
		/>
	);
};

const TradePageGrid = () => {
	const isMobileScreenSize = useIsMobileScreenSize();
	const [settings] = useCurrentSettings();
	const isLiteMode = useIsLiteMode();

	useDisplayStalePageWarning();
	useSwiftAnnouncementNotificationModal();

	return (
		<div
			className={`flex flex-col h-full sm:px-4 ${
				isLiteMode ? 'sm:mt-2 mb-5' : 'mb-2'
			}`}
		>
			{isMobileScreenSize ? (
				// Old grid that works with mobile
				<MobileTradePage />
			) : (
				<>
					{settings.showMarketBar !== false && !isLiteMode && (
						<div className="pb-2">
							<MarketBar />
						</div>
					)}
					<OuterGrid />
				</>
			)}
		</div>
	);
};

export default TradePageGrid;
