'use client';

import React, { useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import Orderbook from './Orderbook/Orderbook';
import RecentMarketTrades from './RecentMarketTrades';
import HighlightedTabs from './TabbedNavigation/HighlightedTabs';
import CandleTypeSelector from './TradingView/CandleTypeSelector';
import { FEATURE_FLAGS } from '@drift/common';
import PositionLinesAndOrdersToggle from './PositionLinesAndOrdersToggle';
import useIsLiteMode from 'src/hooks/useIsLiteMode';

type MobileTradeTab = 'chart' | 'orderbook' | 'recentTrades';

const TVChartContainer = dynamic(
	() =>
		import('./TradingView').then(
			(mod) =>
				mod.TVChartContainer as React.ComponentType<{ isMobile?: boolean }>
		),
	{ ssr: false }
);

const tabOptions = [
	{ value: 'chart', label: 'Chart' },
	{ value: 'orderbook', label: 'Orderbook' },
	{ value: 'recentTrades', label: 'Recent Trades' },
];

const MobileChartAndOrderbook = () => {
	const isLiteMode = useIsLiteMode();
	const controlsRef = useRef<HTMLDivElement>(null);
	const [selectedTab, setSelectedTab] = useState<MobileTradeTab>('chart');

	const orderbookHeight = `calc(100% - ${
		controlsRef?.current?.clientHeight ?? 0
	}px`;

	const handleChangeTabletTab = (tab: string) => {
		setSelectedTab(tab as MobileTradeTab);
	};

	const chartSection = (
		<>
			<TVChartContainer isMobile />
			{selectedTab === 'chart' && (
				<div
					className="flex flex-row items-center justify-between p-1 pt-0 pb-2 bg-container-bg"
					ref={controlsRef}
				>
					<PositionLinesAndOrdersToggle />
					{selectedTab === 'chart' && FEATURE_FLAGS.UI_ORACLE_CANDLES && (
						<CandleTypeSelector isMobile />
					)}
				</div>
			)}
		</>
	);

	if (isLiteMode) {
		return (
			<div className="relative flex flex-col h-full border-b border-container-border">
				<div
					className={`mobile-chart-height sm:h-full w-full bg-container-bg flex flex-col`}
				>
					{chartSection}
				</div>
			</div>
		);
	}

	return (
		<div className="relative flex flex-col h-full border-b border-container-border">
			<HighlightedTabs
				onChange={handleChangeTabletTab}
				currentSelection={selectedTab}
				options={tabOptions}
				opts={{
					fullWidth: true,
					growTabs: true,
				}}
			/>
			<div
				className={`mobile-chart-height sm:h-full w-full bg-container-bg flex flex-col ${
					selectedTab !== 'chart' ? 'hidden' : ''
				}`}
			>
				{chartSection}
			</div>
			<div
				className={`mobile-chart-height sm:h-full w-full bg-container-bg ${
					selectedTab !== 'orderbook' ? 'hidden' : ''
				}`}
			>
				<Orderbook />
			</div>
			<div
				style={{ height: orderbookHeight }}
				className={`mobile-chart-height sm:h-full w-full bg-container-bg ${
					selectedTab !== 'recentTrades' ? 'hidden' : ''
				}`}
			>
				<div className="flex flex-col mobile-chart-height sm:h-full">
					<RecentMarketTrades />
				</div>
			</div>
		</div>
	);
};

export default MobileChartAndOrderbook;
