import React from 'react';

import Tooltip from './Tooltip';
import Text from '../Text/Text';
import Env from 'src/environmentVariables/EnvironmentVariables';

const FuelBoostTooltip = ({
	fuelBoostTaker,
	fuelBoostMaker,
	fuelBoostPosition,
	fuelBoostBorrows,
	fuelBoostDeposits,
	fuelBoostInsurance,
	marketSymbol,
}: {
	fuelBoostTaker?: number;
	fuelBoostMaker?: number;
	fuelBoostPosition?: number;
	fuelBoostBorrows?: number;
	fuelBoostDeposits?: number;
	fuelBoostInsurance?: number;
	marketSymbol?: string;
}) => {
	if (
		(fuelBoostTaker ?? 0) <= 1 &&
		(fuelBoostMaker ?? 0) <= 1 &&
		(fuelBoostPosition ?? 0) <= 1 &&
		(fuelBoostBorrows ?? 0) <= 1 &&
		(fuelBoostDeposits ?? 0) <= 1 &&
		(fuelBoostInsurance ?? 0) <= 1
	) {
		return null;
	}

	return (
		<Tooltip
			content={
				<div className="flex flex-col gap-2">
					{fuelBoostTaker > 1 && (
						<div className="flex flex-row gap-2">
							<img
								src="/assets/images/fuel/fuel-droplet.svg"
								alt="Fuel droplet"
								className="w-5 h-5"
							/>
							<Text.BODY3>
								Boosted: Earn{' '}
								{(Env.fuelBaseEarnRates.taker || 1) * fuelBoostTaker} FUEL for
								every $1 in taker volume
							</Text.BODY3>
						</div>
					)}
					{fuelBoostMaker > 1 && (
						<div className="flex flex-row gap-2">
							<img
								src="/assets/images/fuel/fuel-droplet.svg"
								alt="Fuel droplet"
								className="w-5 h-5"
							/>
							<Text.BODY3>
								Boosted: Earn{' '}
								{(Env.fuelBaseEarnRates.maker || 1) * fuelBoostMaker} FUEL for
								every $1 in maker volume
							</Text.BODY3>
						</div>
					)}
					{fuelBoostPosition > 1 && (
						<div className="flex flex-row gap-2">
							<img
								src="/assets/images/fuel/fuel-droplet.svg"
								alt="Fuel droplet"
								className="w-5 h-5"
							/>
							<Text.BODY3>
								Boosted: Earn{' '}
								{(Env.fuelBaseEarnRates.oi || 1) * fuelBoostPosition} FUEL for
								every $1 in open interest held for 28 days.
							</Text.BODY3>
						</div>
					)}
					{fuelBoostBorrows > 1 && (
						<div className="flex flex-row gap-2">
							<img
								src="/assets/images/fuel/fuel-droplet.svg"
								alt="Fuel droplet"
								className="w-5 h-5"
							/>
							<Text.BODY3>
								Boosted: Earn{' '}
								{(Env.fuelBaseEarnRates.borrow || 1) * fuelBoostBorrows} FUEL
								for every $1 of {marketSymbol} borrowed for 28 days
							</Text.BODY3>
						</div>
					)}

					{fuelBoostDeposits > 1 && (
						<div className="flex flex-row gap-2">
							<img
								src="/assets/images/fuel/fuel-droplet.svg"
								alt="Fuel droplet"
								className="w-5 h-5"
							/>
							<Text.BODY3>
								Boosted: Earn{' '}
								{(Env.fuelBaseEarnRates.deposit || 1) * fuelBoostDeposits} FUEL
								for every $1 of {marketSymbol} deposited for 28 days
							</Text.BODY3>
						</div>
					)}

					{fuelBoostInsurance > 1 && (
						<div className="flex flex-row gap-2">
							<img
								src="/assets/images/fuel/fuel-droplet.svg"
								alt="Fuel droplet"
								className="w-5 h-5"
							/>
							<Text.BODY3>
								Boosted: Earn{' '}
								{(Env.fuelBaseEarnRates.staking || 1) * fuelBoostInsurance}{' '}
								additional FUEL for every {marketSymbol} staked for 28 days
							</Text.BODY3>
						</div>
					)}
				</div>
			}
		>
			<img
				src="/assets/images/fuel/fuel-droplet.svg"
				alt="Fuel droplet"
				className="w-5 h-5"
			/>
		</Tooltip>
	);
};

export default React.memo(FuelBoostTooltip);
